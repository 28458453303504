import { get } from "lodash-es";
import { LogOut, Menu, Repeat, X } from "lucide-react";
import Link from "next/link";
import { useState } from "react";

import { MenuItem } from "@/components/sidebar/menu-item";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { Button } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import useAuthStore from "@/stores/auth-store";

export function MobileMenu({
  menu,
  isUniversity,
  totalPendingApprove,
  totalPendingApprovals,
  chatSummary,
  userType,
  toggleUserType,
  expandSubItem,
  setExpandSubItem,
}: any) {
  const { logout, user } = useAuthStore();
  const [expanded, setExpanded] = useState(false);

  function toggleMenu() {
    setExpanded(!expanded);
  }

  return (
    <div
      className={cn(
        "max-h-available fixed top-0 z-10 flex w-full max-w-[100vw] flex-col overflow-y-auto bg-emerald-black text-slate-200 transition-all md:hidden",
        expanded ? "h-fit" : "h-[80px] overflow-hidden",
      )}
    >
      <div
        className={cn(
          "flex h-[81px] items-center justify-between px-4 py-5",
          expanded && "border-b border-b-coffee-500",
        )}
      >
        <div>
          <div className="whitespace-nowrap font-logo text-base font-extrabold text-coffee-200">
            <Link
              href={isUniversity ? "/hosting" : "/home"}
              onClick={() => setExpanded(false)}
            >
              <img src="/nav-logo.svg" alt="logo" />
            </Link>
          </div>
          <div className="mt-2 whitespace-nowrap text-xs tracking-wide text-coffee-200">
            {isUniversity ? "Lab Director View" : "Researcher View"}
          </div>
        </div>
        <Button
          variant="ghost"
          className="h-8 w-8 rounded p-1 text-coffee-100"
          onClick={toggleMenu}
        >
          {expanded ? <X size={24} /> : <Menu size={24} />}
        </Button>
      </div>
      <menu className="space-y-2 text-slate-200">
        {menu.map(({ label, icon, href, subItems }: any) => (
          <div key={label}>
            <MenuItem
              label={label}
              icon={icon}
              href={href}
              subItems={subItems}
              isUniversity={isUniversity}
              totalPendingApprove={totalPendingApprove}
              totalPendingApprovals={totalPendingApprovals}
              chatSummary={chatSummary}
              expanded={true}
              toggleMenu={toggleMenu}
              expandSubItem={expandSubItem}
              setExpandSubItem={setExpandSubItem}
            />
          </div>
        ))}
      </menu>
      <div className={cn("relative z-20 mt-auto flex items-center")}>
        <div
          className={cn(
            "flex flex-grow cursor-pointer justify-between border-t border-coffee-500 p-4",
          )}
        >
          <div className="flex items-center">
            <Avatar className="h-10 w-10">
              <AvatarImage src="" alt="Avatar" />
              <AvatarFallback className="bg-menu-foreground text-white">
                {get(user, "first_name[0]", "").toUpperCase() +
                  get(user, "last_name[0]", "").toUpperCase()}
              </AvatarFallback>
            </Avatar>
            {expanded && (
              <div className="ml-2 space-y-1">
                <p className="text-sm font-medium leading-none">
                  {user?.first_name} {user?.last_name}
                </p>
                <p className="text-sm capitalize text-[#C8C6C1]">
                  {get(user, "orgs[0].user_role")}
                </p>
              </div>
            )}
          </div>
          <div className="flex items-center gap-2">
            {["company", "university"].includes(userType) && (
              <div>
                <Button
                  className={cn("h-10 w-10 rounded-full bg-menu-opacity")}
                  onClick={toggleUserType}
                >
                  <Repeat size={20} strokeWidth={1} className="rotate-90" />
                </Button>
              </div>
            )}
            <Button
              className="h-10 w-10 rounded-full bg-menu-opacity"
              onClick={() => logout(true)}
              title="Log out"
            >
              <LogOut strokeWidth={1} />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
